import React, { useEffect, useState } from "react";
import { useMoralisQuery, useNewMoralisObject } from "react-moralis";
import { Button, Flex, Stack, Center, VStack, Box } from "@chakra-ui/react";
import { AdvancedRealTimeChart } from 'react-ts-tradingview-widgets'

export const ChartsDashboard = (props) => {
    const useScript = url => {
        useEffect(() => {
            const script = document.createElement('script');
        
            script.src = url;
            script.async = true;
        
            document.body.appendChild(script);
        
            return () => {
              document.body.removeChild(script);
            }
          }, [url]);
    }
    return (
        <VStack>
            Charts!
            <Box>
                
            <AdvancedRealTimeChart theme="dark" height={500}></AdvancedRealTimeChart>
            </Box>
            <Box>
            <AdvancedRealTimeChart theme="dark" height={500}></AdvancedRealTimeChart>
            </Box>
        </VStack>
    )
};
